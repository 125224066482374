<template>
    <div class="navbar-floating">
        <div id="unsubscribe-page-content">
            <div class="header">
                <img src="/img/logo.fb7aaf8c.png" alt="Logo happydom" height="42" style=" position: absolute; width: 195px; height: 42px; left: 20px; top: 9px;">
            </div>

            <div class="tw-w-full">

                <div class="mb-10">
                    <div class="lg:tw-block mb-10 center" v-if="status === '200'">
                        <h2 class="mb-8">Désinscription</h2>
                        <p>Bonjour <strong>{{ civility }} {{ firstName }} {{ lastName }}</strong>,<br><br>Nous confirmons la désinscription de votre compte <strong>{{ email }}</strong> aux notifications vous alertant des suggestions de produits ou services remontés par le personnel de l’EHPAD. <br><br> Vous pourrez cependant toujours traiter ces suggestions dans la plateforme happydom, et réactiver ces notifications dans les paramétrages de votre compte. </p><br><br>
                        <a href="https://happydom-auth-api.happytal.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fscope%3Dopenid%2520profile%2520email%2520happydom-user-data%2520happydom-api-gateway%26response_type%3Dcode%26client_id%3DEhpadSaas.Front%26code_challenge%3DlK5cQap0yV2AEk7uzk6rMvLVgfyZUoEnQpsLRV3XB3Y%26code_challenge_method%3DS256%26redirect_uri%3Dhttps%253A%252F%252Fwww.happydom.fr%252Foauth%252Freturn%252F%26acr_values%3Dtenant%253AEhpadSaas">Me&nbsp;connecter&nbsp;à&nbsp;happydom</a>
                    </div>

                    <div v-if="status === '400'" class="error-message">Le lien est invalide. Votre demande n'a pas pu être traitée. </div>

                    <div v-if="status === '500'" class="error-message"> Une erreur est survenue. Vous pouvez nous contacter directement au <a href="tel:0186760598" class="ui--link" style="color: #17D3C3;">01&nbsp;86&nbsp;76&nbsp;05&nbsp;98</a> . </div>

                </div>

            </div>

            <footer class="the-footer justify-between">
                <p>
                    <span>COPYRIGHT &copy;</span>
                    <span>{{ new Date().getFullYear() }} </span>
                    <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>
                    <span>, Tous droits réservés</span>
                </p>
            </footer>
        </div>
            
    </div>
</template>

<script>

import Http from '@/http'

export default {
    data () {
        return {
            email:'',
            civility:'',
            firstName:'',
            lastName:'',
            status:'',
        }
    },
    methods: {
        unsubscribeEmail(topic, token) {

            Http.apis.ehpad.post(`/subscriptions/unsubscribe`, {
                        topic,
                        token
                    })

            .then( (response) => {
            this.status = '200'
            this.$vs.loading.close()
            this.email = response.data.user.email
            this.civility = response.data.user.civility
            if (this.civility === 'M'){this.civility = 'Mr'}
            this.firstName = response.data.user.firstName
            this.lastName = response.data.user.lastName
            })

            .catch( (error) => {
            this.$vs.loading.close()
            if (error.response.status === 500){
                this.status = '500'
            } else if (error.response.status === 400){
                this.status = '400'
            }
            console.log(this.status)
            })
        }
    },
    created() {
        this.unsubscribeEmail(this.$route.query.topic, this.$route.query.token)
        this.$vs.loading()
    }
}
</script>


<style lang="scss" scoped>
#unsubscribe-page-content {
    padding-top:120px;
    h2{
        font-weight: 700;
    }
    .vx-card {
        background: none;
        border-radius: 0;
        box-shadow: none;
        -webkit-transition: none;
        left: 1.53%;
        width:96%;
    }
    .m-t-30 {
        margin-top: 40px !important;
    }
    .center a{
        background-color:#17D3C3;
        border-radius: 8px;
        height: 42px;
        padding: 12px 24px 12px 24px;
        color: white;
        font-weight: 700;
    }
    p{
        font-size: 14px;
    }
    footer {
        position: absolute;
        bottom: 0px;
        left: 0%;
        right: 0%;
        p {
            margin: 0 auto;
            display: block;
            text-align: center;
        }
        a {
            color: rgba(var(--vs-primary), 1);
        }
    }
    a {
        color: rgb(1, 207, 232);
    }
    .header{
        background-color: #FFFFFF ;
        border-radius: 8px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
        position: absolute;
        left: 1.53%;
        right: 2.78%;
        top: 2.93%;
        bottom: 91.02%;
        height: 62px;
    }
    .error-message{
        margin-left: 30px;  
    }
    .center{
        margin: auto;
        padding-bottom: 45px;

        @media (min-width: 900px) {
            width: 40%;
        }
        @media (max-width: 900px) {
            width: 90%;
        }
    }
}
</style>